.drag-area {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes reflect {
  0% {opacity: 1;}
  50% {opacity: 0.4;}  
  100% {opacity: 1;}  
}