body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
  display: grid;
  background: #333e5a;
}

#root {
}

a {
  color: #fff;
}

header {
  background: #f0293e;
  color: #fff;
  text-align: center;
}

main {
  min-height: 80vh;
  background: #fff;
}
main .controls {
  text-align: center;
  padding: 0.5em 0;
  background: #333e5a;
  color: #fff;
}
main canvas {
  padding: 20px;
  margin: 0 auto;
  display: block;
}
footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
}